<script>
import Vue from "vue";
import Swal from "sweetalert2";
import Steps from "../../components/v-steps";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import Email from "../../components/email";
import checklist from "../../components/checklist";
import {disabledBeforeTodayDates,getUserText,downFile,formatDate,dateDiff,formatEmailInfo,getLookupItemText,checkRole,checkUser} from '@/apis/common'
import {queryStaffList} from '@/apis/user'
import {serviceOrderSchedule,generateConfirmLetter,setServiceOrderStatus,reportReviewApplication,reportReviewConfirm,setServiceOrderCS,setServiceOrderReview} from '@/apis/serviceorder'
import store from '@/state/store'
import {getTemplate,sendEmail} from '@/apis/email'
import {
  required
} from "vuelidate/lib/validators";

export default {
  props:{
    serviceOrder:{
      type: Object,
      required: true
    }
  },
  validations: {
    schedule: {
      serviceDate: { 
            required,
      },
      serviceDateEnd: { 
            required,
      },
      schedulingDate:{
        req(value){
            console.info(value)
            if (this.serviceOrder.schedulingMode=="3"){
              return value!=null && value.trim().length > 0
            }else{
              return true;
            } 
        },
      },
      schedulingDateEnd:{
        req(value){
            if (this.serviceOrder.schedulingMode=="3"){
              return value!=null && value.trim().length > 0
            }else{
              return true;
            } 
        },
      },
    }
  },
  components: {Steps,DatePicker,Multiselect,Email,checklist},
  data() {
    return {
      stepMap:{
        1:"PS",
        2:"SC",
        3:"P",
        4:"IP",
        5:"PP",
        6:"PR",
        7:"RF",
        8:"F"
      },
      curCS:[],
      csList:[],
      submitted:false,
      showSendMail:false,
      checklistShow : false,
      showLog:false,
      staffList:[],
      travelList:[{value:1,label:"Pre-Travel"},{value:2,label:"After-Travel"}],
      templateId:"CONFIRM-LETTER-HIGG",
      confirmLetterEmail:{},
      confirmationLetterLanguage:"EN",
      languageList:[{languageName:"Chinese",languageCode:"CN"},{languageName:"English",languageCode:"EN"},{languageName:"Vietnamese",languageCode:"VN"}],            
      lookupData:{
        clientSource:[],
        companyType:[],
        industry:[]
      },
      disabledDates: disabledBeforeTodayDates,
      showScheduleForm:false,
      verificationFiles:[],
      schedule:{
        serviceDate:"",
        serviceDateEnd:"",
        schedulingDate:"",
        schedulingDateEnd:"",
        workers:[],
        dataWorkers:[],
        reviewer:[],
        travels:[],
        dataTravels:[],
        observers:[],
        observerTravels:[],
      },
      showReviewSurvey:false,
      reviewSurvey:[
        {
          i18n:"review-survey.category1",
          text:"1.Report Format",
          questionList:[
            {
              i18n:"review-survey.q11",
              text:"1.1 Correct format/version of report/onsite CAP template are used",
              rating:"",
              remark:""
            },{
              i18n:"review-survey.q12",
              text:"1.2 The correct format/version of the supplemental documents are used",
              rating:"",
              remark:""
            },{
              i18n:"review-survey.q13",
              text:"1.3 All applicable sections (checklist, checkbox, questions, table, codes, ect) in the report/supplemental documents are properly and completely filled out",
              rating:"",
              remark:""
            }
            
          ]
        },{
          i18n:"review-survey.category2",
          text:"2.Verification Rating and Findings",
          questionList:[
            {
              i18n:"review-survey.q21",
              text:"2.1 All checklist, check boxes, questions, and code provision are correctly answered",
              rating:"",
              remark:""
            },{
              i18n:"review-survey.q22",
              text:"2.2 'Accurate'  is selected when the information provided by the facility in the self-assessment response is 100% correct and supported by data. ",
              rating:"",
              remark:""
            },{
              i18n:"review-survey.q23",
              text:"2.3 'Updated during Verification' is selected when the verifier arrives onsite the Facility Profile circumstances may be different from what they were during the self/joint-assessment.",
              rating:"",
              remark:""
            },{
              i18n:"review-survey.q24",
              text:"2.4  'Inaccurate-Incorrect’ is selected when information provided by the facility in the self-assessment response has one or more errors becasuse facility made a mistake or did not share accurate /honste data( e.g., business license is actually not valid anymore).",
              rating:"",
              remark:""
            },{
              i18n:"review-survey.q25",
              text:"2.5 'Inaccurate-Misunderstanding' is selected when the facility has misunderstood the question( e.g., wrong understanding of 'worker' and including supervisors in the calculations of total number of workers)",
              rating:"",
              remark:""
            },{
              i18n:"review-survey.q26",
              text:"2.6 'Not applicable due to special facility circumstances' is selected if something is 'not applicable' if the question has no 'Not Applicable' option. If this Assessor/Verifier Selection is chosen by the verifier, then the Assessor/Verifier Response column remains blank and must not be completed because the question is not applicable.",
              rating:"",
              remark:""
            },{
              i18n:"review-survey.q27",
              text:"2.7 If Assessor/Verifier Selection is 'Updated during Verification'/'Inaccurate-Incorrect'/'Inaccurate-Misunderstanding'/Facility did not reploy during SA/JA'/'Not visible to facility during SA/JA', the verifier should select correct selection in the blank of 'Assessor/Verifier Response'",
              rating:"",
              remark:""
            },{
              i18n:"review-survey.q28",
              text:"2.8 If Assessor/Verifier Selection is 'Updated during Verification'/'Inaccurate-Incorrect'/'Inaccurate-Misunderstanding'/Facility did not reploy during SA/JA'/'Not visible to facility during SA/JA', the verifier should fill the explanation and facility details in narrative in the blank of 'Assessor/Verifier Explanation'",
              rating:"",
              remark:""
            },{
              i18n:"review-survey.q29",
              text:"2.9 If Non-compliance is seleced, the verifier should fill in legal reference information in the blank of 'Legal Reference'.",
              rating:"",
              remark:""
            },{
              i18n:"review-survey.q210",
              text:"2.10 If 'Assessor/Verifier Selection' is 'Inaccurate-Incorrect'/ 'Inaccurate-Misunderstanding', or 'Non-Compliance' is selected, the verifier MUST Upload if proof/documentation available.",
              rating:"",
              remark:""
            }
          ]
        },{
          i18n:"review-survey.category3",
          text:"3.Verification Procedures",
          questionList:[
            {
              i18n:"review-survey.q31",
              text:"3.1 Verification scope and sample size are accurately performed",
              rating:"",
              remark:""
            },{
              i18n:"review-survey.q32",
              text:"3.2 All non conformance issues are reviewed and addressed properly (follow up/re Verification only)",
              rating:"",
              remark:""
            },{
              i18n:"review-survey.q33",
              text:"3.3 Report and supplemental documents are submitted accurately and timely",
              rating:"",
              remark:""
            },{
              i18n:"review-survey.q34",
              text:"3.4 If the verification is Full Virtual Verfication, the verifier MUST verify that the facility has completed and signed the Full Virtual Verification Self-declaration form and provided evidence attesting to the eligibility requirements for force majeure.",
              rating:"",
              remark:""
            },{
              i18n:"review-survey.q35",
              text:"3.5 If the verification is Full Virtual Verfication, the verifier MUST confirm that all policies and procedures are attached to the applicable data point/question in the self/joint-assessment prior to starting the Full Virtual Verification.",
              rating:"",
              remark:""
            },{
              i18n:"review-survey.q36",
              text:"3.6 If the verification is Full Virtual Verfication, the verifier MUST attach the WE Tech Summary Report to the final verified assessment for question FP-BAS-27 in the Worker Engagament Category to the Facility Profile Section of the report.",
              rating:"",
              remark:""
            },{
              i18n:"review-survey.q37",
              text:"3.7 The number of worker interviewed onsite is in compliance with the requirement of SLCP protocol.",
              rating:"",
              remark:""
            },{
              i18n:"review-survey.q38",
              text:"3.8 The arranged verification person day is consistent with the scope of worker number in Facility Profile module.",
              rating:"",
              remark:""
            },{
              i18n:"review-survey.q39",
              text:"3.9 The worker number is consistent with the definition of worker listed in SLCP guidance.",
              rating:"",
              remark:""
            }
          ]
        },{
          i18n:"review-survey.category4",
          text:"4.Report Writing",
          questionList:[
            {
              i18n:"review-survey.q41",
              text:"4.1 Information and findings are consistent & accurate throughout the report(s)",
              rating:"",
              remark:""
            },{
              i18n:"review-survey.q42",
              text:"4.2 Sufficient comments are provided for facility responses that are marked as 'Inaccurate-Incorrect'/'Inaccurate-Misunderstanding'/'Non-compliance'",
              rating:"",
              remark:""
            },{
              i18n:"review-survey.q43",
              text:"4.3 'Flag' or the icon of flag are selected or marked if non-compliance is selected.",
              rating:"",
              remark:""
            },{
              i18n:"review-survey.q44",
              text:"4.4 The report is logical, apprehensible and well organized",
              rating:"",
              remark:""
            },{
              i18n:"review-survey.q45",
              text:"4.5 The report is written in English, and Spelling and grammar are accurately used",
              rating:"",
              remark:""
            },{
              i18n:"review-survey.q46",
              text:"4.6 Report writing follows Work Instructions and client requirement",
              rating:"",
              remark:""
            }
          ]
        },{
          i18n:"review-survey.category5",
          text:"5.Others",
          questionList:[
            {
              i18n:"review-survey.q51",
              text:"5.1 Are there any other areas of opportunities for improvement? (please specify)",
              rating:"",
              remark:""
            },{
              i18n:"review-survey.q52",
              text:"5.2 Cusomter feedback on report quality (if any)",
              rating:"",
              remark:""
            },{
              i18n:"review-survey.q53",
              text:"5.3 Details should be provided about what is not in compliance with applicable legal requirements and legal reference should be provided at minimum and consisted of the specific location of the applicable law in the law text, such as name of the law or regulation, year or version no., article.",
              rating:"",
              remark:""
            },{
              i18n:"review-survey.q54",
              text:"5.4 For the question'HS-CHE-10-2','HS-CHE-4' and 'HS-CHE-5', if the options were not ticked, the flag should be marked and the applicable legal laws should be provided. <br>HS-CHE-10-2: Appropriate containers are used for dispensing all chemicals<br>HS-CHE-10-4: A complete spill kit (appropriate for the types and use of chemicals in the facility) is available for use<br>HS-CHE-10-5: Chemicals are not disposed of in waste bin containers which are generally used for food and drink",
              rating:"",
              remark:""
            }
          ]
        }
      ],
      higgReviewSurvey:[
        {
          i18n:"higg-review-survey.category1",
          text:"1.Report Format",
          questionList:[
            {
              i18n:"higg-review-survey.q11",
              text:"1.1 Correct format/version of report/onsite CAP template are used",
              rating:"",
              remark:""
            },{
              i18n:"higg-review-survey.q12",
              text:"1.2 The correct format/version of the supplemental documents are used",
              rating:"",
              remark:""
            },{
              i18n:"higg-review-survey.q13",
              text:"1.3 All applicable sections (checklist, checkbox, questions, table, codes, ect) in the report/supplemental documents are properly and completely filled out",
              rating:"",
              remark:""
            }					            
          ]
        },{
          i18n:"higg-review-survey.category2",
          text:"2.Verification Rating and Findings",
          questionList:[
            {
              i18n:"higg-review-survey.q21",
              text:"2.1 Correct overall Verification rating/score is given  	",
              rating:"",
              remark:""
            },{
              i18n:"higg-review-survey.q22",
              text:"2.2 All checklist, check boxes, questions, and code provision are correctly answered",
              rating:"",
              remark:""
            },{
              i18n:"higg-review-survey.q23",
              text:"2.3 'Accurate'’ are selected when the self-assessment response is accurate",
              rating:"",
              remark:""
            },{
              i18n:"higg-review-survey.q24",
              text:"2.4 'Inaccurate'  are selected when the self-assessment response is not accurate",
              rating:"",
              remark:""
            },{
              i18n:"higg-review-survey.q25",
              text:"2.5  'No Response’ are selected when the facility’s self-assessment does not include a response to the question, or the question was opened due to a change in applicability questions or level achievement.",
              rating:"",
              remark:""
            },{
              i18n:"higg-review-survey.q26",
              text:"2.6  If a facility's response is inaccurate and needs to be changed/updated by the Verifier, the Verification response for the input are rated as 'Inaccurate'. ",
              rating:"",
              remark:""
            }
          ]
        },{
          i18n:"higg-review-survey.category3",
          text:"3.Verification Procedures",
          questionList:[
            {
              i18n:"higg-review-survey.q31",
              text:"3.1 Verification scope and sample size are accurately performed",
              rating:"",
              remark:""
            },{
              i18n:"higg-review-survey.q32",
              text:"3.2 All non conformance issues are reviewed and addressed properly (follow up/re Verification only)",
              rating:"",
              remark:""
            },{
              i18n:"higg-review-survey.q33",
              text:"3.3 Report and supplemental documents are submitted accurately and timely",
              rating:"",
              remark:""
            }
          ]
        },{
          i18n:"higg-review-survey.category4",
          text:"4.Report Writing",	
          questionList:[
            {
              i18n:"higg-review-survey.q41",
              text:"4.1 Information and findings are consistent & accurate throughout the report(s)",
              rating:"",
              remark:""
            },{
              i18n:"higg-review-survey.q42",
              text:"4.2 Sufficient comments are provided for facility responses that are marked as inaccurate and updated",
              rating:"",
              remark:""
            },{
              i18n:"higg-review-survey.q43",
              text:"4.3 The report is logical, apprehensible and well organized",
              rating:"",
              remark:""
            },{
              i18n:"higg-review-survey.q44",
              text:"4.4 Spelling and grammar are accurately used	",
              rating:"",
              remark:""
            },{
              i18n:"higg-review-survey.q45",
              text:"4.5 Report writing follows Work Instructions and client requirement",
              rating:"",
              remark:""
            }
          ]
        },{
          i18n:"higg-review-survey.category5",
          text:"5.Others",
          questionList:[					
            {
              i18n:"higg-review-survey.q51",
              text:"5.1 Are there any other areas of opportunities for improvement? (please specify)",
              rating:"",
              remark:""
            },{
              i18n:"higg-review-survey.q52",
              text:"5.2 Cusomter feedback on report quality (if any)",
              rating:"",
              remark:""
            }
          ]
        },{
          i18n:"higg-review-survey.category6",
          text:"6.Commonly Made Mistakes During Verifications",
          questionList:[					
            {
              i18n:"higg-review-survey.q61",
              text:"6.1 Facility address is verified against bsuinsess license and the correct one is listed in the facility overview page",
              rating:"",
              remark:""
            },{
              i18n:"higg-review-survey.q62",
              text:"6.2 Partial points are awarded for facility with annual emergency drill (Twice a year is expected)",
              rating:"",
              remark:""
            },{
              i18n:"higg-review-survey.q63",
              text:"6.3 Target Change format are correctly imported (minus for reduction and plus for growth)",
              rating:"",
              remark:""
            },{
              i18n:"higg-review-survey.q64",
              text:"6.4 Target Type (Ablolute or Normalized) are correctly selected for Water/Energy/Waste improvements. ",
              rating:"",
              remark:""
            },{
              i18n:"higg-review-survey.q65",
              text:"6.5 Quantity achieved format are correctly imported (minus for reduction and plus for growth) for water/energy/waste improvements.",
              rating:"",
              remark:""
            },{
              i18n:"higg-review-survey.q66",
              text:"6.6 All materials type are slected for which are produced or used in the manufacturing process (direct materials and indirect materials).",
              rating:"",
              remark:""
            },{
              i18n:"higg-review-survey.q67",
              text:"6.7 Yes is not selected if facility has not met the full Yes requirement for Wastewater Q5.",
              rating:"",
              remark:""
            },{
              i18n:"higg-review-survey.q68",
              text:"6.8 Operating days are consistent in Site info and Water sections",
              rating:"",
              remark:""
            },{
              i18n:"higg-review-survey.q69",
              text:"6.9 All required topics are checked and confirmed in the training material as per the full Yes requirement for Chem Q7",
              rating:"",
              remark:""
            }
          ]
        }
      ]
    };
  },
  computed:{
    reviewRating(){
      let count=0;
      let rating=0.0;
      if(this.serviceOrder.serviceType=='SLCP'){
        for(var i=0;i<this.reviewSurvey.length;i++){
          for(var j=0;j<this.reviewSurvey[i].questionList.length;j++){
            if(this.reviewSurvey[i].questionList[j].rating!=-1&&this.reviewSurvey[i].questionList[j].rating!=''){
              count++;
              rating+=parseFloat(this.reviewSurvey[i].questionList[j].rating)
            }
          }
        }
      }else{
        for(var ii=0;ii<this.higgReviewSurvey.length;ii++){
          for(var jj=0;jj<this.higgReviewSurvey[ii].questionList.length;jj++){
            if(this.higgReviewSurvey[ii].questionList[jj].rating!=-1&&this.higgReviewSurvey[ii].questionList[jj].rating!=''){
              count++;
              rating+=parseFloat(this.higgReviewSurvey[ii].questionList[jj].rating)
            }
          }
        }
      }
      if(count===0){
        return ""
      }else{
        return (rating/count).toFixed(1)
      }
    },
    showAssignButton(){
      return checkRole("CS_LEADER,SYS_ADMIN") 
    },
    stepsLabel(){
      return [this.$t("serviceOrder.stepPS"), this.$t("serviceOrder.stepSC"), this.$t("serviceOrder.stepP"), this.$t("serviceOrder.stepIP"), this.$t("serviceOrder.stepPP"), this.$t("serviceOrder.stepPR"), this.$t("serviceOrder.stepRF"), this.$t("serviceOrder.stepF")]
    },
    announcedType:function(){
      if(this.serviceOrder.schedulingMode=="2" ||this.serviceOrder.schedulingMode=="3"){
        return true
      }else{
        return false
      }
    },
    schedulingModeText:function(){
      if(this.serviceOrder.schedulingMode=="1"){
        return this.$t("serviceOrder.announced")
      }else if(this.serviceOrder.schedulingMode=="2"){
        return this.$t("serviceOrder.unannounced")
      }else if(this.serviceOrder.schedulingMode=="3"){
        return this.$t("serviceOrder.semiAnnounced")
      }else{
        return ""
      }
    },
    userInfo:function(){
      if(store.state.auth.currentUser){
        return store.state.auth.currentUser
      }else{
        return {nickName:'',userName:''}
      }
    },
    reviewerList(){
      let workers = this.serviceOrder.workers;
      if(workers!=null && workers!=undefined){
        return this.staffList.filter((item)=>{return !workers.includes(item.userName)})
      }else{
        return []
      }
      
    },
    order(){
      if(this.serviceOrder.order==null){
        return {}
      }else{
        return this.serviceOrder.order
      }
    },
    currentStep(){
      for(let index in this.stepMap){
        if(this.stepMap[index]==this.serviceOrder.serviceStatus){
          return parseInt(index)
        }
      }
      return 1
    },
    showScheduleButton(){
      let role = checkRole("CS,SYS_ADMIN")
      if(this.serviceOrder.serviceStatus=="PS" || this.serviceOrder.serviceStatus=="SC" ||(this.serviceOrder.confirmationLetterReceipt==null && this.serviceOrder.confirmationLetterReceipt=="")){
        return true && role
      }else{
        return false && role
      }
    }
  },
  watch: {
  },
  created(){
    this.init()
  },
  mounted() {
  },
  methods: {
    handleCloseChecklistModal(item){
      this.serviceOrder.assessmentId = item.assessmentId
      this.serviceOrder.reportPostDeadline = item.reportPostDeadline
      this.checklistShow = false
    },
    handleAssignCS(){
      this.$refs.csModal.show()
    },
    assignCS(){
      let cs = this.curCS.userName
      setServiceOrderCS({serviceId:this.serviceOrder.serviceId,cs:cs}).then(()=>{
        this.$refs.csModal.hide()
        Vue.swal(this.$t("common.assignCompleted"));
      })
    },
    getAuditUserText(user){
      return getUserText(this.staffList,user)
    },
    getTravelText(value){
      return getLookupItemText(this.travelList,value)
    },
    
    async init(){
      await queryStaffList({roles:["AUDIT"]}).then((res)=>{
          this.staffList = res.data;
      })
      await queryStaffList({roles:["CS"]}).then((res)=>{
          this.csList = res.data;
      })
    },
    changeStatusEnable(index){    
      //服务进行中以前步骤不允许自行设置
      if(index<4)return false
      let curIndex = 1;
      for(let key in this.stepMap){
        if(this.stepMap[key]==this.serviceOrder.serviceStatus){
          curIndex = parseInt(key)
          break
        }
      }
      //后4步由对应审核员操作
      if(curIndex>=index){
        return false;
      }else{
        if(curIndex+1==index){
          let finishOp = checkUser(this.serviceOrder.worker) || checkUser(this.serviceOrder.reviewer)
          let reviewOp = checkUser(this.serviceOrder.worker) || checkUser(this.serviceOrder.cs)
          let workerOp = checkUser(this.serviceOrder.worker) || checkUser(this.serviceOrder.dataWorker)
          //设置状态
          if(index==8 && finishOp){
            //完成状态进行提醒
            Swal.fire({
              text: this.$t("serviceOrder.infoCompleted"),
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#34c38f",
              cancelButtonColor: "#f46a6a",
              confirmButtonText: this.$t("actions.ok"),
              cancelButtonText: this.$t("actions.cancel")
            }).then(result => {
              if (result.value) {
                if(checkUser(this.serviceOrder.worker)){
                  // 审核员操作Review，提示客服进行Review人员分配
                  reportReviewConfirm({serviceId:this.serviceOrder.serviceId,}).then((res)=>{
                    this.serviceOrder.logs = res.data.logs
                    Vue.swal(this.$t("serviceOrder.infoWaitingReportReviewerConfirm"));
                  })
                }else if(checkRole("SYS_ADMIN") ||checkUser(this.serviceOrder.reviewer)){
                  setServiceOrderStatus({serviceId:this.serviceOrder.serviceId,serviceStatus:this.stepMap[8]}).then((res)=>{
                    this.serviceOrder.logs = res.data.logs
                    this.serviceOrder.serviceStatus = res.data.serviceStatus
                  })
                }
              }
            });
          }else if(index==7 && checkUser(this.serviceOrder.reviewer)){
            this.handleReportReviewFinish();
          }else if(index==6 && reviewOp){
            if(checkRole("CS,SYS_ADMIN")){
              this.$refs.reviewerModal.show()
            }else if(checkUser(this.serviceOrder.worker)){
              Swal.fire({
              text: this.$t("serviceOrder.infoWorkFileUpload"),
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#34c38f",
              cancelButtonColor: "#f46a6a",
              confirmButtonText: this.$t("actions.ok"),
              cancelButtonText: this.$t("actions.cancel")
            }).then(result => {
              if (result.value) {
                if(checkRole("AUDIT")){
                  // 审核员操作Review，提示客服进行Review人员分配
                  reportReviewApplication({serviceId:this.serviceOrder.serviceId,}).then((res)=>{
                    this.serviceOrder.logs = res.data.logs
                    Vue.swal(this.$t("serviceOrder.infoReviewRequest"));
                  })
                }
              }
            });
            }
            return false;
          }else if(workerOp){
            setServiceOrderStatus({serviceId:this.serviceOrder.serviceId,serviceStatus:this.stepMap[index]}).then((res)=>{
              this.serviceOrder.logs = res.data.logs
              this.serviceOrder.serviceStatus = res.data.serviceStatus
              if(index==4&&this.serviceOrder.serviceType=="SLCP"){
                Vue.swal(this.$t("serviceOrder.confirmManday"));
              }else{
                Vue.swal(this.$t("serviceOrder.infoStatusSet"));
              }              
            })
          }
          return false;
        }
      }
    },
    handleChangeStatus(index){
      console.info(index)
    },
    handleFollowup(){
      this.$router.push({
          name: "follow-up",
          query: {
              id: this.serviceOrder.serviceId,
              type:'service',
          }
      })
    },
    handleSchedule(){
      this.schedule.serviceDate = this.serviceOrder.serviceDate
      this.schedule.serviceDateEnd = this.serviceOrder.serviceDateEnd
      this.schedule.schedulingDate = this.serviceOrder.schedulingDate
      this.schedule.schedulingDateEnd = this.serviceOrder.schedulingDateEnd
      this.schedule.workers = []
      this.schedule.dataWorkers = []
      this.schedule.travels = []
      this.schedule.dataTravels = []
      this.schedule.observers = []
      this.schedule.observerTravels = []
      if(this.serviceOrder.workers!=null){
        this.serviceOrder.workers.forEach((item)=>{
          let staffs = this.staffList.filter((staff)=>{
              return staff.userName == item
          })
          if(staffs.length==1){
            this.schedule.workers.push({userName:item,staffName:staffs[0].staffName})
          }
        })
      }
      if(this.serviceOrder.travels!=null){
        this.serviceOrder.travels.forEach((item)=>{
          let travels = this.travelList.filter((travel)=>{
              return travel.value == item
          })
          if(travels.length==1){
            this.schedule.travels.push({value:item,label:travels[0].label})
          }
        })
      }
      if(this.serviceOrder.dataWorkers!=null){
        this.serviceOrder.dataWorkers.forEach((item)=>{
          let staffs = this.staffList.filter((staff)=>{
              return staff.userName == item
          })
          if(staffs.length==1){
            this.schedule.dataWorkers.push({userName:item,staffName:staffs[0].staffName})
          }
        })
      }
      if(this.serviceOrder.dataTravels!=null){
        this.serviceOrder.dataTravels.forEach((item)=>{
          let travels = this.travelList.filter((travel)=>{
              return travel.value == item
          })
          if(travels.length==1){
            this.schedule.dataTravels.push({value:item,label:travels[0].label})
          }
        })
      }
      if(this.serviceOrder.observers!=null){
        this.serviceOrder.observers.forEach((item)=>{
          let staffs = this.staffList.filter((staff)=>{
              return staff.userName == item
          })
          if(staffs.length==1){
            this.schedule.observers.push({userName:item,staffName:staffs[0].staffName})
          }
        })
      }
      if(this.serviceOrder.observerTravels!=null){
        this.serviceOrder.observerTravels.forEach((item)=>{
          let travels = this.travelList.filter((travel)=>{
              return travel.value == item
          })
          if(travels.length==1){
            this.schedule.observerTravels.push({value:item,label:travels[0].label})
          }
        })
      }
      this.showScheduleForm = true;
    },
    handleSubmitSchedule(){
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      } else {
        debugger
        this.showScheduleForm = false;
        let params = {...this.schedule}
        if(this.schedule.workers.length>0){
          params.worker = this.schedule.workers.map(item=>{return item.userName}).join(",")
        }
        if(this.schedule.travels!=null){
          params.travelOption = this.schedule.travels.map(item=>{return item.value}).join(",")
        }
        if(this.schedule.dataWorkers.length>0){
          params.dataWorker = this.schedule.dataWorkers.map(item=>{return item.userName}).join(",")
        }
        if(this.schedule.dataTravels!=null){
          params.dataWorkerTravelOption = this.schedule.dataTravels.map(item=>{return item.value}).join(",")
        }
        if(this.schedule.observers.length>0){
          params.observer = this.schedule.observers.map(item=>{return item.userName}).join(",")
        }
        if(this.schedule.observerTravels!=null){
          params.observerTravelOption = this.schedule.observerTravels.map(item=>{return item.value}).join(",")
        }
        params.serviceId = this.serviceOrder.serviceId
        // 删除reviewer
        delete params.reviewer
        serviceOrderSchedule(params).then(()=>{
          this.serviceOrder.serviceDate = params.serviceDate
          this.serviceOrder.serviceDateEnd = params.serviceDateEnd
          this.serviceOrder.schedulingDate = params.schedulingDate
          this.serviceOrder.schedulingDateEnd = params.schedulingDateEnd
          debugger
          this.serviceOrder.worker = params.worker
          this.serviceOrder.workers = params.worker.split(",")
          if(params.travelOption!=undefined && params.travelOption!=null){
            this.serviceOrder.travels = params.travelOption.split(",")
          }          
          if(params.dataWorker!=undefined && params.dataWorker!=null){
            this.serviceOrder.dataWorker = params.dataWorker
            this.serviceOrder.dataWorkers = params.dataWorker.split(",")
            if(params.dataWorkerTravelOption!=undefined && params.dataWorkerTravelOption!=null){
              this.serviceOrder.dataTravels = params.dataWorkerTravelOption.split(",")
            }            
          }
          if(params.observer!=undefined && params.observer!=null){
            this.serviceOrder.observer = params.observer
            this.serviceOrder.observers = params.observer.split(",")
            if(params.observerTravelOption!=undefined && params.observerTravelOption!=null){
              this.serviceOrder.observerTravels = params.observerTravelOption.split(",")
            }            
          }
          if(this.serviceOrder.serviceStatus=="PS"){
            this.serviceOrder.serviceStatus = "SC"
          }
          this.$forceUpdate()
        })
      }      
    },
    handleCancelSchedule(){
      this.showScheduleForm = false;
      this.submitted = false;
    },
    actionEnable(actionType){
      let role = checkRole("CS,SYS_ADMIN")
      if(actionType=="Generate" && this.serviceOrder.serviceStatus=="SC"){
        return true&&role
      }else if(actionType=="Send" && this.serviceOrder.serviceStatus=="SC" && this.serviceOrder.confirmationLetterFile!=null){
        return true&&role
      }else if(actionType=="Down-Conf-Letter" && this.serviceOrder.confirmationLetterFile!=null){
        return true&&role
      }else if(actionType=="Down-Conf-Letter-Rec" && this.serviceOrder.confirmationLetterReceipt!=null){
        return true
      }else if(actionType=="Review" && this.serviceOrder.serviceStatus=="PR" && this.serviceOrder.reviewer==this.userInfo.userName){
        return true
      }else if(actionType=="Send-Finish" && this.serviceOrder.serviceStatus=="F" && this.serviceOrder.cs==this.userInfo.userName ){
        return true
      }else if(actionType=="Send-PreAudit" &&this.serviceOrder.serviceType=="Higg"&&(this.serviceOrder.serviceStatus=="SC"||this.serviceOrder.serviceStatus=="P") && this.serviceOrder.cs==this.userInfo.userName){
        return true&&role
      }else if(actionType=="Send-Survey" && this.serviceOrder.survey==null && this.serviceOrder.serviceStatus=="F" && this.serviceOrder.cs==this.userInfo.userName){
        return true
      }else if(actionType=="CheckList"&& (this.serviceOrder.serviceType=="SLCP"||this.serviceOrder.serviceType=="Higg")){
        return true&&role
      }
      return false
    },
    handleDownload (file){
        const url=`/api-gateway/service/download?fileType=QT&fileName=${file}`
        downFile(url,file);
    },
    handleLog(){
      this.showLog = !this.showLog;
    },
    handleGenerateLetter(){
      if(this.serviceOrder.confirmationLetterFile!=null){
        Swal.fire({
          text: this.$t("serviceOrder.infoConfirmCreateLetter"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: this.$t("actions.ok"),
          cancelButtonText: this.$t("actions.cancel")
        }).then(result => {
          if (result.value) {
            this.generateConfirmLetterFile()
          }
        });
      }else{
        this.generateConfirmLetterFile()
      }
    },
    generateConfirmLetterFile(){
      this.$refs.languageModal.show()      
    },
    generateConfirmLetter(){
      generateConfirmLetter({serviceId:this.serviceOrder.serviceId,language:this.confirmationLetterLanguage.languageCode}).then((res)=>{
        this.$refs.languageModal.hide()      
        if(res.data==null){
          Vue.swal(this.$t("serviceOrder.errorGenerateConfirmationLetter"));
        }else{
          this.serviceOrder.confirmationLetterFile = res.data
          Vue.swal(this.$t("serviceOrder.infoConfirmationGenerated"));
        }
      })
    },
    handleSendLetter(emailType){
      this.confirmLetterEmail.attachment = []
      this.confirmLetterEmail.emailData = {}
      if(emailType==1){
        //确认函
        this.confirmLetterEmail.to = this.serviceOrder.order.email
        this.confirmLetterEmail.attachment = [{name:"确认函",file:this.serviceOrder.confirmationLetterFile}]
        this.confirmLetterEmail.emailData.serviceId = this.serviceOrder.serviceId
        this.confirmLetterEmail.emailData.clientName = this.serviceOrder.order.clientName
        this.confirmLetterEmail.emailData.clientNameEn = this.serviceOrder.order.clientNameEn
        this.confirmLetterEmail.emailData.workDay = this.serviceOrder.workDay
        let orderDetail = JSON.parse(this.serviceOrder.order.orderDetail)
        this.confirmLetterEmail.emailData.serviceType = this.serviceOrder.order.orderType+" "+orderDetail.type+" "+orderDetail.model
        this.confirmLetterEmail.emailData.contact = this.serviceOrder.order.contact
        this.confirmLetterEmail.emailData.serviceDate = formatDate(this.serviceOrder.serviceDate,"yyyy-MM-dd")
        this.confirmLetterEmail.emailData.fileDate = formatDate(dateDiff(this.serviceOrder.serviceDate,-1),"yyyy-MM-dd")
        this.confirmLetterEmail.emailData.vbConfirmDate = formatDate(dateDiff(this.serviceOrder.serviceDate,-7),"yyyy-MM-dd")
        //let attachmentType = "";
        if(this.serviceOrder.order.orderType=="SLCP"){
          this.templateId = "CONFIRM-LETTER-SLCP"
         // attachmentType = "SLCP_VF_FILE"
          //SLCP_VF_FILE,CCUPI,CEL
        }else if(this.serviceOrder.order.orderType=="Higg"){
          this.templateId = "CONFIRM-LETTER-HIGG"
          //attachmentType = "HIGG_VF_FILE" 
          //HIGG_VF_FILE,CCUPI,CEL
        }
      }else if(emailType==2){
        //报告发布通知
        this.confirmLetterEmail.to = this.serviceOrder.order.email
        this.confirmLetterEmail.emailData.serviceId = this.serviceOrder.serviceId
        this.confirmLetterEmail.emailData.clientName = this.serviceOrder.order.clientName
        this.confirmLetterEmail.emailData.clientNameEn = this.serviceOrder.order.clientNameEn
        this.confirmLetterEmail.emailData.contact = this.serviceOrder.order.contact
        if(this.serviceOrder.order.orderType=="SLCP"){
          this.templateId = "POST-NOTICE-SLCP"
        }else if(this.serviceOrder.order.orderType=="Higg"){
          this.templateId = "POST-NOTICE-HIGG"
        }
      }else if(emailType==3){
        //预审通知
        this.confirmLetterEmail.to = this.serviceOrder.order.email
        this.confirmLetterEmail.emailData.serviceId = this.serviceOrder.serviceId
        this.confirmLetterEmail.emailData.clientName = this.serviceOrder.order.clientName
        this.confirmLetterEmail.emailData.clientNameEn = this.serviceOrder.order.clientNameEn
        this.confirmLetterEmail.emailData.contact = this.serviceOrder.order.contact
        if(this.serviceOrder.order.orderType=="Higg"){
          this.templateId = "QUANTITATIV-MV-HIGG"
        }
      }else if(emailType==4){
        //问卷调查
        this.confirmLetterEmail.to = this.serviceOrder.order.email
        this.confirmLetterEmail.emailData.serviceId = this.serviceOrder.serviceId
        this.confirmLetterEmail.emailData.clientName = this.serviceOrder.order.clientName
        this.confirmLetterEmail.emailData.clientNameEn = this.serviceOrder.order.clientNameEn
        this.confirmLetterEmail.emailData.contact = this.serviceOrder.order.contact
        this.templateId = "SERVICE-SURVEY"
      }     
      this.showSendMail=true
    },
    handleSendEmailResult(res){
      if(res.code==0){
        this.showSendMail=false
      }else if(res.code==1){
        Vue.swal(this.$t("serviceOrder.infoEmailSent"));
        this.showSendMail=false
      }else{
        Vue.swal(this.$t("serviceOrder.errorEmailSent"));
      }      
    },
    handleReviewer(){
      let reviewer = this.schedule.reviewer.userName
      setServiceOrderStatus({serviceId:this.serviceOrder.serviceId,serviceStatus:"PR",reviewer:reviewer}).then((res)=>{
        this.serviceOrder.logs = res.data.logs
        this.serviceOrder.serviceStatus = res.data.serviceStatus
        this.$refs.reviewerModal.hide()
        Vue.swal(this.$t("serviceOrder.infoReportReviewsuccess"));
      })
    },
    handleCancelReviewForm(){
      this.showReviewSurvey = false
    },
    handleSubmitReviewForm(){
      let result = true
      if(this.serviceOrder.serviceType=='SLCP'){
        for(var i=0;i<this.reviewSurvey.length;i++){
        for(var j=0;j<this.reviewSurvey[i].questionList.length;j++){
          if(this.reviewSurvey[i].questionList[j].rating===''){            
            result = false;
            break;
          }
        }
      }
      }else{
        for(var ii=0;ii<this.higgReviewSurvey.length;ii++){
          for(var jj=0;jj<this.higgReviewSurvey[ii].questionList.length;jj++){
            if(this.higgReviewSurvey[ii].questionList[jj].rating===''){            
              result = false;
              break;
            }
          }
        }
      }
      if(!result){
        Vue.swal(this.$t("serviceOrder.errorReview"));
        return;
      }
      Swal.fire({
          text: this.$t("serviceOrder.infoConfirmReportReview"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: this.$t("actions.ok"),
          cancelButtonText: this.$t("actions.cancel")
        }).then(result => {
          if (result.value) {
            let params={
              serviceId:this.serviceOrder.serviceId,
              details:this.serviceOrder.serviceType=='SLCP'?JSON.stringify(this.reviewSurvey):JSON.stringify(this.higgReviewSurvey),
              rating:this.reviewRating
            }
            setServiceOrderReview(params).then(()=>{
              this.serviceOrder.serviceStatus = "RF"
              this.showReviewSurvey = false
              //邮件通知审核员
              this.sendNoticetoAudit("NOTICE-REVIEW-REPORT_FINISHED")
            }).catch(()=>{
                Vue.swal(this.$t("serviceOrder.errorRetrievinTemplate"));
            });
          }
       });     
    },
    sendNoticetoAudit(templateCode){
      getTemplate(templateCode).then((res)=>{
        let audit = this.staffList.filter(item=>{return item.userName==this.serviceOrder.worker});
        let params = {
            to:audit[0].email,
        }
        let emailData = {
          serviceId:this.serviceOrder.serviceId,
          serviceNo:this.serviceOrder.serviceNo,
          audit:this.serviceOrder.worker,
          clientName:this.serviceOrder.order.clientName
        }
        formatEmailInfo("zh-CN",params,emailData,res.data)
        sendEmail(params).then(()=>{
          Vue.swal(this.$t("serviceOrder.infoReportReviewCompletion"));
        }).catch(()=>{
          Vue.swal(this.$t("serviceOrder.errorSendReportReview"));
        })
      }).catch(()=>{
        Vue.swal(this.$t("serviceOrder.errorRetrievinTemplate"));
      });
    },
    handleReportReviewFinish(){
      debugger
      if(this.serviceOrder.serviceType=="SLCP" ||this.serviceOrder.serviceType=="Higg"){
        //SLCP需要提交REVIEW评审表
        this.showReviewSurvey = true;   
      }else{
        Swal.fire({
          text: this.$t("serviceOrder.infoConfirmReportReview"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: this.$t("actions.ok"),
          cancelButtonText: this.$t("actions.cancel")
        }).then(result => {
          if (result.value) {
            setServiceOrderStatus({serviceId:this.serviceOrder.serviceId,serviceStatus:this.stepMap[7]}).then((res)=>{
              this.serviceOrder.logs = res.data.logs
              this.serviceOrder.serviceStatus = res.data.serviceStatus
            })
            this.sendNoticetoAudit("NOTICE-REVIEW-REPORT_FINISHED")
          }
        });
      }
    }
  }
};
</script>

<template>
  <div class="mb-0">
    <div class="row">
      <Steps
      :currentStep="currentStep"
      :totalSteps="8"
      :stepsLabel="stepsLabel"
      :totalWidth="900"
      :descMaxWidth="160"
      :validation="changeStatusEnable"
      @change="handleChangeStatus" />
    </div>
    <hr class="my-4">
    <div class="row">
      <div class="float-end d-md-block mb-3">
        <button type="button" class="btn btn-primary btn-sm mb-1" @click="handleFollowup"><i class="fab fa-rocketchat me-1 align-middle" ></i>{{$t("actions.followup")}}</button>
        <button type="button" class="btn btn-primary btn-sm mb-1 ms-1" @click="handleAssignCS" v-if="showAssignButton">{{$t("actions.assignCS")}}</button>
        <button type="button" class="btn btn-primary btn-sm mb-1 ms-1" v-if="actionEnable('Generate')" @click="handleGenerateLetter"><i class="fas fa-plus me-1 align-middle" ></i>{{$t("serviceOrder.gConfirmationLetter")}}</button>
        <button type="button" class="btn btn-primary btn-sm mb-1 ms-1" v-if="actionEnable('Down-Conf-Letter')" @click="handleDownload(serviceOrder.confirmationLetterFile)"><i class="ion ion-md-cloud-download me-1 align-middle" ></i>{{$t("serviceOrder.confirmationLetter")}}</button>
        <button type="button" class="btn btn-primary btn-sm mb-1 ms-1" v-if="actionEnable('Down-Conf-Letter-Rec')" @click="handleDownload(serviceOrder.confirmationLetterReceipt)"><i class="ion ion-md-cloud-download me-1 align-middle" ></i>{{$t("serviceOrder.reconfirmationLetter")}}</button>
        <button type="button" class="btn btn-primary btn-sm mb-1 ms-1" v-if="actionEnable('Send')" @click="handleSendLetter(1)"><i class="ion ion-md-send me-1 align-middle" ></i> {{$t("serviceOrder.sConfirmationLetter")}}</button>
        <button type="button" class="btn btn-primary btn-sm mb-1 ms-1" v-if="actionEnable('Send-Finish')" @click="handleSendLetter(2)"><i class="ion ion-md-send me-1 align-middle" ></i> {{$t("serviceOrder.sReportUpload")}}</button>
        <button type="button" class="btn btn-primary btn-sm mb-1 ms-1" v-if="actionEnable('Send-PreAudit')" @click="handleSendLetter(3)"><i class="ion ion-md-send me-1 align-middle" ></i> {{$t("serviceOrder.sPreVerified")}}</button>
        <button type="button" class="btn btn-primary btn-sm mb-1 ms-1" v-if="actionEnable('Send-Survey')" @click="handleSendLetter(4)"><i class="ion ion-md-send me-1 align-middle" ></i> {{$t("serviceOrder.sSurvey")}}</button>
        <button type="button" class="btn btn-primary btn-sm mb-1 ms-1" @click="handleLog"><i class="fas fa-calendar-alt me-1 align-middle" ></i>{{$t("serviceOrder.operationLog")}}</button>
        <button type="button" class="btn btn-primary btn-sm mb-1 ms-1" v-if="actionEnable('CheckList')" @click="checklistShow=true"><i class="fas fa-check-circle me-1 align-middle" ></i>工作检查点</button>
      </div>
    </div>
    <div class="row" v-if="showSendMail">
        <email :templateId="templateId" :data="confirmLetterEmail" @send="handleSendEmailResult"></email>
    </div>
    <div class="row" v-if="!showSendMail && !showReviewSurvey">
      <div class="col-md-12">
        <b-alert variant="danger" class="mb-0" :show="announcedType">
          {{$t("serviceOrder.notesAnnouncedType")}}:{{ schedulingModeText }}
        </b-alert>
      </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <div class="row">
                  <div class="col-md-12 mb-3">
                    <div class="infoField d-flex align-items-center">
                      <div class="flex-grow-1">{{$t("serviceOrder.schedulingInfo")}}({{ serviceOrder.workDay }} {{ $t("serviceOrder.personday")}}):</div>
                      <button type="button" class="btn btn-success btn-sm" @click="handleSchedule" v-if="showScheduleButton">{{$t("serviceOrder.btSchedule")}}</button>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="!showScheduleForm">
                  <div class="col-md-12">
                      <div class="infoField">{{$t("serviceOrder.verificationStartDate")}}</div>
                      <div class="infoData">{{serviceOrder.serviceDate}}</div>
                  </div>
                  <div class="col-md-12">
                      <div class="infoField">{{$t("serviceOrder.verificationEndDate")}}</div>
                      <div class="infoData">{{serviceOrder.serviceDateEnd}}</div>
                  </div>
                  <div class="col-md-12">
                      <div class="infoField">{{$t("serviceOrder.announcedType")}}</div>
                      <div class="infoData">{{schedulingModeText}}</div>
                  </div>
                  <div class="col-md-12" v-if="serviceOrder.schedulingMode=='3'">
                      <div class="infoField">{{$t("serviceOrder.announcedStartDate")}}</div>
                      <div class="infoData">{{serviceOrder.schedulingDate}}</div>
                  </div>
                  <div class="col-md-12" v-if="serviceOrder.schedulingMode=='3'">
                      <div class="infoField">{{$t("serviceOrder.announcedEndDate")}}</div>
                      <div class="infoData">{{serviceOrder.schedulingDateEnd}}</div>
                  </div>
                  <div class="col-md-12">
                      <div class="infoField">{{$t("serviceOrder.leadVerifier")}}</div>
                      <div class="infoData">
                        <span class="badge me-1 badge-soft-info" v-for="item in serviceOrder.workers" :key="item">{{getAuditUserText(item)}}</span>
                      </div>
                  </div>  
                  <div class="col-md-12">
                      <div class="infoField">{{$t("serviceOrder.travelSchedule")}}</div>
                      <div class="infoData">
                        <span class="badge me-1 badge-soft-info" v-for="item in serviceOrder.travels" :key="item">{{getTravelText(item)}}</span>
                      </div>
                  </div> 
                  <div class="col-md-12">
                      <div class="infoField">{{$t("serviceOrder.quantitativeMetricsVerifier")}}</div>
                      <div class="infoData">
                        <span class="badge me-1 badge-soft-info" v-for="item in serviceOrder.dataWorkers" :key="item">{{getAuditUserText(item)}}</span>
                      </div>
                  </div>  
                  <div class="col-md-12">
                      <div class="infoField">{{$t("serviceOrder.travelScheduleQM")}}</div>
                      <div class="infoData">
                        <span class="badge me-1 badge-soft-info" v-for="item in serviceOrder.dataTravels" :key="item">{{getTravelText(item)}}</span>
                      </div>
                  </div> 
                  <div class="col-md-12">
                      <div class="infoField">{{$t("serviceOrder.observer")}}</div>
                      <div class="infoData">
                        <span class="badge me-1 badge-soft-info" v-for="item in serviceOrder.observers" :key="item">{{getAuditUserText(item)}}</span>
                      </div>
                  </div>  
                  <div class="col-md-12">
                      <div class="infoField">{{$t("serviceOrder.travelScheduleObserver")}}</div>
                      <div class="infoData">
                        <span class="badge me-1 badge-soft-info" v-for="item in serviceOrder.observerTravels" :key="item">{{getTravelText(item)}}</span>
                      </div>
                  </div>                   
                  <div class="col-md-12">
                      <div class="infoField">{{$t("serviceOrder.scheduleConfirmationDate")}}</div>
                      <div class="infoData">{{serviceOrder.confirmationLetterReceiptDate}}</div>
                  </div>      
              </div>
              <div class="row" v-if="showScheduleForm">
                <div class="col-md-12 mb-3">
                    <label class="form-label">{{$t("serviceOrder.verificationStartDate")}}</label>
                    <date-picker
                      v-model="schedule.serviceDate"
                      format="YYYY-MM-DD"
                      value-type="format"
                      :disabled-date="disabledDates"
                      :first-day-of-week="1"
                      lang="en"
                      :class="{
                          'is-invalid': submitted && $v.schedule.serviceDate.$error,
                      }"
                    ></date-picker>
                    <div
                        v-if="submitted && $v.schedule.serviceDate.$error"
                        class="invalid-feedback"
                        >
                            <span v-if="!$v.schedule.serviceDate.required">
                              开始日期不能为空.
                            </span>
                    </div>
                </div>
                <div class="col-md-12 mb-3">
                    <label class="form-label">{{$t("serviceOrder.verificationEndDate")}}</label>
                    <date-picker
                      v-model="schedule.serviceDateEnd"
                      format="YYYY-MM-DD"
                      value-type="format"
                      :disabled-date="disabledDates"
                      :first-day-of-week="1"
                      lang="en"
                      :class="{
                          'is-invalid': submitted && $v.schedule.serviceDateEnd.$error,
                      }"
                    ></date-picker>
                    <div
                        v-if="submitted && $v.schedule.serviceDateEnd.$error"
                        class="invalid-feedback"
                        >
                            <span v-if="!$v.schedule.serviceDateEnd.required">
                              结束日期不能为空.
                            </span>
                    </div>
                </div>
                <div class="col-md-12 mb-3">
                  <label class="form-label">{{$t("serviceOrder.leadVerifier")}}</label>
                  <multiselect v-model="schedule.workers" :options="staffList" :multiple="true"
                      label="staffName"
                      track-by="userName"
                  ></multiselect>
                </div>
                <div class="col-md-12 mb-3">
                  <label class="form-label">{{$t("serviceOrder.travelSchedule")}}</label>
                  <multiselect v-model="schedule.travels" :options="travelList" :multiple="true"
                      label="label"
                      track-by="value"
                  ></multiselect>
                </div>
                <div class="col-md-12 mb-3">
                  <label class="form-label">{{$t("serviceOrder.quantitativeMetricsVerifier")}}</label>
                  <multiselect v-model="schedule.dataWorkers" :options="staffList" :multiple="true"
                      label="staffName"
                      track-by="userName"
                  ></multiselect>
                </div>
                <div class="col-md-12 mb-3">
                  <label class="form-label">{{$t("serviceOrder.travelScheduleQM")}}</label>
                  <multiselect v-model="schedule.dataTravels" :options="travelList" :multiple="true"
                      label="label"
                      track-by="value"
                  ></multiselect>
                </div>
                <div class="col-md-12 mb-3">
                  <label class="form-label">{{$t("serviceOrder.observer")}}</label>
                  <multiselect v-model="schedule.observers" :options="staffList" :multiple="true"
                      label="staffName"
                      track-by="userName"
                  ></multiselect>
                </div>
                <div class="col-md-12 mb-3">
                  <label class="form-label">{{$t("serviceOrder.travelScheduleObserver")}}</label>
                  <multiselect v-model="schedule.observerTravels" :options="travelList" :multiple="true"
                      label="label"
                      track-by="value"
                  ></multiselect>
                </div>
                <div class="col-md-12 mb-3" v-if="serviceOrder.schedulingMode=='3'">
                    <label class="form-label">{{$t("serviceOrder.announcedStartDate")}}</label>
                    <date-picker
                      v-model="schedule.schedulingDate"
                      format="YYYY-MM-DD"
                      value-type="format"
                      :disabled-date="disabledDates"
                      :first-day-of-week="1"
                      lang="en"
                      :class="{
                          'is-invalid': submitted && $v.schedule.schedulingDate.$error,
                      }"
                    ></date-picker>
                    <div
                        v-if="submitted && $v.schedule.schedulingDate.$error"
                        class="invalid-feedback"
                        >
                            <span v-if="!$v.schedule.schedulingDate.req">
                              开始日期不能为空.
                            </span>
                    </div>
                </div>
                <div class="col-md-12 mb-3" v-if="serviceOrder.schedulingMode=='3'">
                    <label class="form-label">{{$t("serviceOrder.announcedEndDate")}}</label>
                    <date-picker
                      v-model="schedule.schedulingDateEnd"
                      format="YYYY-MM-DD"
                      value-type="format"
                      :disabled-date="disabledDates"
                      :first-day-of-week="1"
                      lang="en"
                      :class="{
                          'is-invalid': submitted && $v.schedule.schedulingDateEnd.$error,
                      }"
                    ></date-picker>
                    <div
                        v-if="submitted && $v.schedule.schedulingDateEnd.$error"
                        class="invalid-feedback"
                        >
                            <span v-if="!$v.schedule.serviceDateEnd.req">
                              结束日期不能为空.
                            </span>
                    </div>
                </div>
                <div class="col-md-12 ">
                  <button type="button" class="btn btn-info btn-sm" @click="handleSubmitSchedule">{{$t("common.submit")}}</button>
                  <button type="button" class="btn btn-light btn-sm ms-1" @click="handleCancelSchedule">{{$t("common.cancel")}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6" v-if="showLog">
          <div class="card">
            <div class="card-body">
              <div class="row">
                  <div class="col-md-12 mb-3">
                      <div class="infoField">服务日志:</div>
                  </div>
                  <div class="col-md-12 mb-3">
                    <div class="log-messages">
                      <div class="message-item"  v-for="(item) in serviceOrder.logs" :key="item.id">
                          <span class="icon"></span>
                            <h5>{{item.logDate}}</h5>
                            <p>{{item.description}}</p>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <div class="row">
                  <div class="col-md-12 mb-3">
                      <div class="infoField">{{$t("serviceOrder.contactInfo")}}:</div>
                  </div>
                  <div class="col-md-12">
                      <div class="infoField">{{$t("common.clientNameLocal")}}</div>
                      <div class="infoData">{{ order.clientName }}</div>
                  </div>
                  <div class="col-md-12">
                      <div class="infoField">{{$t("common.clientNameEn")}}</div>
                      <div class="infoData">{{ order.clientNameEn }}</div>
                  </div>
                  <div class="col-md-12">
                      <div class="infoField">{{$t("common.addressLocal")}}</div>
                      <div class="infoData">{{ order.address }}</div>
                  </div>
                  <div class="col-md-12">
                      <div class="infoField">{{$t("common.addressEn")}}</div>
                      <div class="infoData">{{ order.addressEn }}</div>
                  </div>
                  <div class="col-md-12">
                      <div class="infoField">{{$t("common.contact")}}</div>
                      <div class="infoData">{{ order.contact }}</div>
                  </div>
                  <div class="col-md-12">
                      <div class="infoField">{{$t("common.title")}}</div>
                      <div class="infoData">{{ order.title }}</div>
                  </div>
                  <div class="col-md-12">
                      <div class="infoField">{{$t("common.mobile")}}</div>
                      <div class="infoData">{{ order.mobile }}</div>
                  </div>     
                  <div class="col-md-12">
                      <div class="infoField">{{$t("common.email")}}</div>
                      <div class="infoData">{{ order.email }}</div>
                  </div>                
              </div>   
            </div>
          </div>  
        </div>
        <b-modal
          id="reviewerModal"
          ref="reviewerModal"
          centered
          title="报告审核员选择"
          title-class="font-18"
          hide-footer
          >
            <div>
              <multiselect v-model="schedule.reviewer" :options="reviewerList" :multiple="false"
                      label="staffName"
                      track-by="userName"
              ></multiselect>
            </div>
            <div>
              <button type="button" class="btn btn-primary btn-sm" @click="handleReviewer">{{$t("common.ok")}}</button>
            </div>
        </b-modal>
        <b-modal
        id="csModal"
        ref="csModal"
        centered
        :title='$t("actions.assignCS")'
        title-class="font-18"
        hide-footer
        >
          <div>
            <multiselect v-model="curCS" :options="csList" :multiple="false"
                    label="staffName"
                    track-by="userName"
            ></multiselect>
          </div>
          <div>
            <button type="button" class="btn btn-primary btn-sm" @click="assignCS">{{$t("common.ok")}}</button>
          </div>
      </b-modal>
    </div> 
    <div class="row" v-if="showReviewSurvey && serviceOrder.serviceType=='SLCP'">
        <div class="row">
            <div class="col-8" style="font-weight: bold;">Question</div>
            <div class="col-2" style="font-weight: bold;">Rating</div>
            <div class="col-2" style="font-weight: bold;">Remark</div>
        </div>      
        <div v-for="(item) in reviewSurvey" :key="item.i18n" class="row mt-2">
          <h5>{{ item.text }}</h5>
          <div class="row mb-1" v-for="(question) in item.questionList" :key="question.i18n">
              <div class="col-8">{{ question.text }}</div>
              <div class="col-1">
                <div style="padding: 3px;"
                :class="{
                            'rateing-5': question.rating==5,
                            'rateing-4': question.rating==4,
                            'rateing-3': question.rating==3,
                            'rateing-2': question.rating==2,
                            'rateing-1': question.rating==1,
                            'rateing-0': question.rating!=''&&question.rating==0,
                            'rateing-NA': question.rating==-1,
                        }"              
                >
                  <select class="form-select" v-model="question.rating">
                    <option value="5">5</option>
                    <option value="4">4</option>
                    <option value="3" selected>3</option>
                    <option value="2">2</option>
                    <option value="1">1</option>            
                    <option value="0">0</option>
                    <option value="-1" >NA</option>                        
                  </select>
                </div>
              </div>
              <div class="col-3"><input type="text" class="form-control" v-model="question.remark"></div>
          </div>
        </div>
        <div>
          <div class="col-8"></div>
          <div class="col-4" style="font-weight:bold">Over All:{{reviewRating}}</div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12 ">
            <button type="button" class="btn btn-info btn-sm" @click="handleSubmitReviewForm">{{$t("common.submit")}}</button>
            <button type="button" class="btn btn-light btn-sm ms-1" @click="handleCancelReviewForm">{{$t("common.cancel")}}</button>
          </div>
        </div>
        <div class="row mt-5">
          Rating Used (Point 5 to 1)，<br>
          (5) Excellent: Exceeds expectations (wonderful, client will want to hug you!)<br>
          (4) Good:Meets expectations (only 1, if any, clarification on non-key sections needed)<br>
          (3) Acceptable:Meets exceptions (only 1, if any, clarification on key sections needed)<br>
          (2) Need Improvement: Did not meet expectations (considerable clarification was needed)<br>
          (1) Poor: Well below expectation (substantial clarification and revision needed, serious quality concerns to address)<br>
          (NA): Not Applicable element for this report.
        </div>
    </div>
    <div class="row" v-if="showReviewSurvey && serviceOrder.serviceType=='Higg'">
        <div class="row">
            <div class="col-8" style="font-weight: bold;">Question</div>
            <div class="col-2" style="font-weight: bold;">Rating</div>
            <div class="col-2" style="font-weight: bold;">Remark</div>
        </div>      
        <div v-for="(item) in higgReviewSurvey" :key="item.i18n" class="row mt-2">
          <h5>{{ item.text }}</h5>
          <div class="row mb-1" v-for="(question) in item.questionList" :key="question.i18n">
              <div class="col-8">{{ question.text }}</div>
              <div class="col-1">
                <div style="padding: 3px;"
                :class="{
                            'rateing-5': question.rating==5,
                            'rateing-4': question.rating==4,
                            'rateing-3': question.rating==3,
                            'rateing-2': question.rating==2,
                            'rateing-1': question.rating==1,
                            'rateing-0': question.rating!=''&&question.rating==0,
                            'rateing-NA': question.rating==-1,
                        }"              
                >
                  <select class="form-select" v-model="question.rating">
                    <option value="5">5</option>
                    <option value="4">4</option>
                    <option value="3" selected>3</option>
                    <option value="2">2</option>
                    <option value="1">1</option>            
                    <option value="0">0</option>
                    <option value="-1" >NA</option>                        
                  </select>
                </div>
              </div>
              <div class="col-3"><input type="text" class="form-control" v-model="question.remark"></div>
          </div>
        </div>
        <div>
          <div class="col-8"></div>
          <div class="col-4" style="font-weight:bold">Over All:{{reviewRating}}</div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12 ">
            <button type="button" class="btn btn-info btn-sm" @click="handleSubmitReviewForm">{{$t("common.submit")}}</button>
            <button type="button" class="btn btn-light btn-sm ms-1" @click="handleCancelReviewForm">{{$t("common.cancel")}}</button>
          </div>
        </div>
        <div class="row mt-5">
          Rating Used (Point 5 to 1)，<br>
          (5) Excellent: Exceeds expectations (wonderful, client will want to hug you!)<br>
          (4) Good:Meets expectations (only 1, if any, clarification on non-key sections needed)<br>
          (3) Acceptable:Meets exceptions (only 1, if any, clarification on key sections needed)<br>
          (2) Need Improvement: Did not meet expectations (considerable clarification was needed)<br>
          (1) Poor: Well below expectation (substantial clarification and revision needed, serious quality concerns to address)<br>
          (NA): Not Applicable element for this report.
        </div>
    </div>
    <checklist :show="checklistShow" :serviceOrder="serviceOrder" @close="handleCloseChecklistModal"></checklist>  
    <b-modal
        id="languageModal"
        ref="languageModal"
        centered
        :title='$t("serviceOrder.gConfirmationLetter")'
        title-class="font-18"
        hide-footer
        >
        <div>
            <multiselect v-model="confirmationLetterLanguage" :options="languageList" :multiple="false"
                    label="languageName"
                    track-by="languageCode"
            ></multiselect>
        </div>
        <div>
            <button type="button" class="btn btn-primary btn-sm" @click="generateConfirmLetter">{{$t("common.ok")}}</button>
        </div>
    </b-modal>             
  </div>
</template>
<style lang="scss" scoped>
.rateing-5{
  background-color: green;
}
.rateing-4{
  background-color: orange;
}
.rateing-3{
  background-color: yellow;
}
.rateing-2,.rateing-1,.rateing-0{
  background-color: red;
}
.rateing-NA{
  background-color: gray;
}
.infoField{
  font-weight: bold;
}
.infoData{
  min-height: 30px;
}
.is-invalid .mx-input-wrapper .mx-input{
    border: 1px solid #ec4561 !important;
  }
  .is-invalid .mx-input-wrapper .mx-icon-calendar{
    color: #ec4561 !important;
  }
  .log-messages{
      border-left: #bfcbd9 solid 2px;
      padding-left: 20px;
      margin-left: 20px;
        .message-item {
          position: relative;
          span.icon{
            border-radius: 100%;
            border: #bfcbd9 solid 1px;
            background-color: #bfcbd9;
            width: 15px;
            height: 15px;
            font-size: 10px;
            display: inline-block;
            text-align: center;
            position: absolute;
            left: -29px;
            top:4px;
          }
        }
    }
</style>